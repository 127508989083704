<template>
    <div class="w-full h-full px-3 py-4">

        <All model="Comercials" :query="query" :immediate="true" :config="config" v-slot="{data,loading}">

            <div v-if="!loading" class="h-auto">

                <All model="Comercialsdetail" :immediate="true" :query="query" :config="config" v-slot="{data:detail,loading:loading2}">

                    <div v-if="!loading2" class="h-auto">
                        <div class="h-auto w-full ">

                            <div class="h-auto rounded-lg shadow_box flex flex-col p-2 mb-4">
                                <div class="h-12 flex flex-row ">
                                    <div class="h-full w-full flex  items-center px-2 truncate">
                                            <span class="text-xl text-dfont font-semibold truncate">{{nameCompany}}</span>

                                            <!--  -->
                                        </div>
                                        <div class="h-full w-1/12 flex justify-end items-center">

                                            <i class="mdi mdi-dots-vertical text-gray text-xl" @click="toFilters()"></i>
                                        </div>
                                </div>
                                <div class="w-full px-2">
                                    <span class="text-sm" :class="'text-font_gray-'+mode">{{$t('commercial')}}: </span>
                                    <span  class="text-sm font-semibold" :class="{'text-purple': zone == 'Nacional', 'text-blue': zone == 'Exportación'}">{{zone}},</span>
                                    <span class="text-sm ml-2" :class="'text-font_gray-'+mode">{{$t('period')}}: </span>
                                    <span  class="text-sm text-purple font-semibold" >{{period}}</span>
                                </div>
                            </div>

                            <div class="h-auto rounded-lg shadow_box flex flex-col p-2 mb-4">

                                <div class="h-48 flex flex-col justify-center items-center mt-2 relative">

                                    <div class="h-8 w-full flex flex-col justify-center items-start px-2 mt-2">
                                        <div class="h-auto w-full flex flex-row justify-between items-center">
                                            <span class="text-lg text-dfont font-semibold">{{$t('sales')}}</span>
                                            <i class="mdi mdi-dots-vertical absolute top-0 right-0 text-gray text-xl" @click="dimensionFilters()"></i>
                                        </div>

                                        <span class="text-sm text-gray">{{$t('indicator')}}: <span class="text-purple font-semibold">{{dimensionName}} {{periodo}}</span></span>
                                    </div>

                                    <div class="flex-1 w-full relative flex flex-row justify-center items-center">
                                        <donutchart :data="detail"></donutchart>
                                        <div class="h-32 w-32 rounded-full absolute flex flex-col justify-center items-center">
                                            <span class="text-sm text-gray">Total</span>
                                            <span class="text-dfont font-bold">{{getTotalDimension(detail) | reduceBigNumbers()}} <span v-html="unit"></span></span>
                                        </div>
                                    </div>

                                </div>
                                <div class="h-28 overflow-hidden mx-2 listFade">
                                    <div class="h-full overflow-y-auto">
                                        <div class="h-6 py-1 flex items-center mb-1" v-for="(country,index) in detail" :key="index">
                                        <div class="h-full w-3/6 flex flex-row justify-start items-center">
                                            <div class="h-full w-1 rounded-lg mr-2" :class="'bg-'+colors[(index<4) ? index: 4]"></div>
                                            <span class="text-sm" :class="'text-font_gray-'+mode">{{country.Name}}</span>
                                        </div>
                                        <div class="h-full w-2/6 flex flex-row justify-end items-center">
                                            <span class="text-dfont text-sm font-bold">{{country.Dimension | reduceBigNumbers()}} <span v-html="unit"></span></span>
                                        </div>
                                        <div class="h-full w-1/6 flex flex-row justify-end items-center">
                                            <span class="text-xs text-gray">{{country.Percent | reduceBigNumbers()}} %</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div v-else class="h-20 relative">
                        <loader :loading="loading2"></loader>
                    </div>

                </All>

                <div class="rounded-lg bg-white shadow_box p-4 mb-4 ">
                    <div class="w-full h-auto flex flex-col items-start relative">
                        <span class="text-lg text-dfont font-semibold">{{$t('commercial')}}</span>
                        <span class="text-sm text-gray">{{$t('indicator')}}: <span class="text-purple font-semibold">{{dimensionName}} {{periodo}}</span></span>

                        <i class="mdi mdi-dots-vertical absolute top-0 right-0 text-gray text-xl" @click="dimensionFilters()"></i>

                        <div class="h-10 rounded-lg bg-lightgray mt-2 flex flex-row w-full">
                            <div class="h-full w-2/12 flex flex-row justify-center items-center">
                                <i class="mdi mdi-magnify text-gray text-xl"></i>
                            </div>
                            <input type="text" v-model="searchInput" class="w-12/12 h-full bg-lightgray rounded-lg px-2 text-darkgreen">
                        </div>
                    </div>
                </div>

                <!-- <div v-for="(el,key) in filteredComercials(data)" :key="key" class="h-auto w-full">

                    <div v-if="rol==='Propiedad' || rol==='Gerente' || el.Id == user.id" class="h-auto rounded-lg shadow_box flex flex-col p-2 mb-4"  @click="openComercial(el)">

                        <div class="h-16 flex flex-row pl-2">

                            <div class="h-full w-2/12 flex justify-center items-center">
                                <div class="h-12 w-12 rounded-full overflow-hidden">
                                    <vue-initials-img :name="el.Name || el.ZonesName" class="h-12 w-12"/>
                                </div>

                            </div>
                            <div class="h-full w-9/12 flex flex-col justify-center items-start  truncate">
                                <span class="font-semibold text-dfont truncate block">{{el.Name || 'Zona ' + el.ZonesName}}</span>
                                <span class="text-xs text-gray truncate block">{{$t('zone')}} {{el.ZonesName}}</span>
                            </div>

                            <div class="h-full w-1/12 flex  justify-end items-center">
                                <i class="mdi mdi-arrow-right font-semibold text-dfont"></i>
                            </div>

                        </div>

                        <div class="h-auto flex flex-col justify-between py-2">

                            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('day')}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('accumulated')}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('deliveryNotes')}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('scope')}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.MonthPreview | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">Real {{yearant}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.PastYear | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('variation')}} {{periodo}}</span>
                                <span class="text-sm font-bold text-dfont"><span v-if="el.MonthVariation >= 0">+</span>{{el.MonthVariation | reduceBigNumbers(2)}}%</span>
                            </div>

                        </div>

                    </div>

                    <div v-else class="h-auto rounded-lg shadow_box flex flex-col p-2 mb-4">

                        <div v-if="key == comercialSelected || rol == 'Propiedad'" class="h-16 flex flex-row ">

                            <div class="h-full w-2/12 flex justify-center items-center">
                                <div class="h-12 w-12 rounded-full overflow-hidden">
                                    <vue-initials-img :name="el.Name || el.ZonesName" class="h-12 w-12"/>
                                </div>

                            </div>

                            <div class="h-full w-9/12 flex flex-col justify-center items-start pl-2 truncate">
                                <span class="font-semibold text-dfont">{{el.Name || el.ZonesName}}</span>
                                <span class="text-xs text-gray">{{$t('zone')}} {{el.ZonesName}}</span>
                            </div>

                            <div class="h-full w-1/12 flex flex-row justify-center items-center">
                                <i class="mdi mdi-arrow-right font-semibold text-dfont"></i>
                            </div>

                        </div>

                        <div v-else class="h-16 flex flex-row px-2">

                            <div class="h-full w-2/12 flex flex-row justify-center items-center">
                                <div class="h-12 w-12 rounded-full overflow-hidden">
                                    <vue-initials-img :name="el.Name || el.ZonesName" class="h-12 w-12"/>
                                </div>
                            </div>

                            <div class="h-full w-9/12 flex flex-col justify-center items-start pl-2 truncate">
                                <span v-if="el.Name" class="font-semibold text-dfont">{{el.Name}}</span>
                                <span class="text-xs text-gray">{{$t('name')}} {{$t('zone')}}</span>
                            </div>

                            <div class="h-full w-1/12 flex flex-row justify-center items-center">
                                <i class="mdi mdi-arrow-right font-semibold text-dfont"></i>
                            </div>

                        </div>

                        <div class="h-6 w-full mt-2">
                            <span class="text-xs text-darkgreen">
                                {{dimensionName}}
                            </span>
                        </div>

                        <div class="flex-1 flex flex-col justify-between py-2">

                            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('day')}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('accumulated')}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('deliveryNotes')}}</span>
                                <span v-if="dimensionName == 'Precio medio'" class="text-sm text-darkgray">PM </span>
                                <span v-if="dimensionName == 'Margen'" class="text-sm text-darkgray">{{$t('margin')}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('scope')}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.MonthPreview | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">Real {{yearant}}</span>
                                <span class="text-sm font-bold text-dfont">{{el.PastYear | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                <span class="text-sm text-darkgray">{{$t('variation')}} {{periodo}}</span>
                                <span class="text-sm font-bold text-dfont"><span v-if="el.MonthVariation >= 0">+</span>{{el.MonthVariation | reduceBigNumbers(2)}}%</span>
                            </div>

                        </div>

                    </div>

                </div> -->

                <comercial v-for="(el,key) in filteredComercials(data)"
                    :data="el"
                    :key="key"
                    :rol="rol"
                    :dimensionName="dimensionName"
                    @openComercial="openComercial">
                </comercial>

            </div>

            <div v-else class="h-20 relative">
                <loader :loading="loading"></loader>
            </div>

        </All>

    </div>
</template>

<script>
    import { state, actions } from '@/store';
    import solidgauge from '../../components/solidgauge.vue';
    import donutchart from '@/components/donutchart.vue';
    import { All } from '@/api/components';
    import loader from '../../components/loader.vue';
    import doubleChart from '@/components/doubleChart.vue';
    import comercial from '@/components/comercial.vue';

    export default {
        name: 'representante',
        components:{
            solidgauge,
            donutchart,
            All,
            loader,
            doubleChart,
            comercial
        },
        data(){
            return{
                facturation: false,
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
                config:{},
                //nameCompany: null,
                yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
                month: this.$moment().format("MMM 'YY"),
                montprevyear: this.$moment().subtract(1, 'years').format("MMM 'YY"),
                saletype: null,
                searchInput:'',
                countries:[],
                colors:['green', 'purple2', 'yellow', 'blue', 'gray']
            }
        },
        methods:{
            toTops(){
                this.$router.push('grouptops')
            },
            activeFacturation(){
                this.facturation = !this.facturation
            },
            openComercial(comercial){

                if(this.rol == 'Propiedad' || this.rol == 'Gerente' || comercial.Id == this.user.id){

                    actions.setComercialSelected(comercial)
                    actions.setSaleTypeSelected(this.config.data.SaleType)
                    actions.setUserId(comercial.Id)

                    if(comercial.Id){

                        actions.setIdZone(comercial.Id)
                        actions.setFilterZoneComercial(comercial.ZonesId[0].toLowerCase())
                        actions.setFilterZoneComercialName(comercial.ZonesName.split(',')[0])

                    } else {

                        actions.setIdZone(comercial.ZonesId[0])
                        actions.setFilterZoneComercial(comercial.ZonesId[0].toLowerCase())
                        actions.setFilterZoneComercialName(comercial.ZonesName.split(',')[0])

                    }



                    this.$router.push({name: 'comercial', params:{comercial:state.idZone}})

                } else {

                }

            },
            toFilters(){
                this.$router.push({name : 'otherfilters'})
            },
            filteredComercials(data) {
                return Object.values(data).filter(post => {
                    return post['Name'].toLowerCase().includes(this.searchInput.toLowerCase()) || post['ZonesName'].toLowerCase().includes(this.searchInput.toLowerCase())
                })
            },
            getTotalDimension(data){

                var total = 0

                for (let index = 0; index < data.length; index++) {

                    total += data[index].Dimension

                }

                if(this.dimensionName == 'Precio medio' || this.dimensionName == 'Margen'){

                    return (total / data.length)

                } else {

                    return total

                }



            },
            dimensionFilters(){
                this.$router.push({ name: 'groupfilters' });
            }

        },
        computed: {
            user() {
                return state.user;
            },
            zone(){
                return state.zoneSelected;
            },
            comercialSelected(){
                if(state.idZone){
                    return state.idZone
                }

            },
            rol(){
                return state.user.rol.Name
            },
            nameCompany(){
                return state.companySelected
            },
            dimensionGroup(){
                return state.dimensionGroup
            },
            dimensionName(){
                return state.dimensionName
            },
            period() {
                switch(state.period) {
                    case 'year':
                        return this.$t("annual")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    default:
                        return this.$t("monthly")
                }
                return state.period
            },
            unit(){
                let unit = '€'
                if(state.dimensionGroup == 1){
                    unit = 'm<sup>2</sup>'
                }
                if(state.dimensionGroup == 3){
                    unit = '%'
                }
                return unit
            },
            query(){
                return{
                    period: state.period,
                    IndustrialGroup: state.industrialGroup
                }
            },
            comercial(){
                return state.comercialSelected
            },
            modelInfo(){
                if(state.comercialSelected){
                    return
                }
            },
            activeperiod(){
                switch (state.period) {
                    case 'month':
                        return this.$moment().format("MMM 'YY")
                        break;

                    case 'quarter':
                        return 'trim. ' + this.$moment().format("Q 'YY")
                        break;

                    case 'year':
                        return this.$moment().format("YYYY")
                        break;
                }
            },
            prevPeriod(){
                switch (state.period) {
                    case 'month':
                        return this.$moment().subtract(1, 'years').format("MMM 'YY")
                        break;

                    case 'quarter':
                        return 'trim. ' + this.$moment().subtract(1, 'years').format("Q 'YY")
                        break;

                    case 'year':
                        return this.$moment().subtract(1, 'years').format("YYYY")
                        break;
                }
            }
        },
        mounted(){

            if(this.$route.params.saletype){

                this.config = {
                    data:{
                        Company: state.idCompany,
                        Dimension: state.dimensionGroup,
                        SaleType: this.$route.params.saletype
                    }
                }

            } else {

                this.config = {
                    data:{
                        Company: state.idCompany,
                        Dimension: state.dimensionGroup,
                        SaleType: state.saleTypeSelected
                    }
                }

            }

            //this.nameCompany = state.companySelected

        }
    }
</script>

<style>
.listFade{
    position:relative;
}
.listFade::after{
        content: '';
        width:100%;
        height:2rem;
        position:absolute;
        left:0;
        bottom:0;
        background:linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        z-index:100;
    }

</style>
